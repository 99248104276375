.menuPage {
    position: fixed;
    z-index: 1000000;
    background-color: white;
    height: 100vh;
    text-align: center;
}

.menuPage .padding {
    padding: 10px;
   
}

.menuPage .studyabroad {
    width: 100%;
    border: 1px solid rgb(198, 197, 197);
    height: 40px;
    border-radius: 5px;
    background-color: white;
}

.menuPage .hjjhjjhhjhh {
    padding: 7px 20px;
    box-shadow: 1px 1px 9px #9c9c9c94;
}