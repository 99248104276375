.user-app-header {

  background-color: var(--black1);
  padding: 15px 30px;
  z-index: 50;
  position: fixed;
  width: 100%;

}

.scrolled {
  box-shadow: 1px 1px 5px #d9c5c5;
}

.search-container ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

/* Individual list item styling */
.search-container ul li {
  margin-bottom: 10px;
}

/* Button styling for clickable titles */
.search-container ul li button {
  background: none;
  border: none;

  color: #007bff;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
 
  transition: color 0.2s ease;
}

.seact {
  position: absolute;
  background-color: white;
  width: 600px;

}

.seact ul {
  padding: 10px;
}

.seact ul li {
  padding: 1px 20px;

}

.search-container ul li button:hover {
  color: #0056b3;
}

/* Placeholder text for no results */
.no-results {
  text-align: center;
  color: #888;
  font-size: 16px;
  margin-top: 10px;
}

.user-app-header .explore-mores button {
  background-color: #FFFFFF;
  box-shadow: 4px 4px 8.2px 0px #00000040;
  color: #5AA6FF;
  border-radius: 50px;
  font-size: 1vw;
  text-wrap: nowrap;

}

.user-app-header .search-input input {
  border-radius: 50px;
  background-color: #E4F1FF;
  border: 1px solid #5AA6FF;
  font-size: 1vw;
}

.user-app-header .search-input {
  position: relative;
}

.user-app-header .search-input input::placeholder {
  color: rgb(185, 184, 184);
  font-size: 15px;

}

.user-app-header .search-input input:focus {

  box-shadow: none;
}

.user-app-header .logo img {
  width: 26%;
}

.user-app-header .search-input img {
  padding: 2px;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 3px solid black;
  margin-top: 10px;
  margin-right: 10px;
  width: 20px;
}

.user-app-header .icon-box button {
  background-color: #FFFFFF;
  box-shadow: 4px 4px 8.2px 0px #00000040;
  color: #5AA6FF;
  border-radius: 50px;
  font-size: 1vw;
  text-wrap: nowrap;

}

.Mobile {
  padding: 10px;
  position: fixed;
  background-color: #f6f7fb;
  z-index: 55;
  display: none;
}

.user-app-header {
  display: block;
}


.Mobile .icon {
  font-size: 24px;
}

.Mobile .event_explore_more {
  background-color: var(--green1);
  color: white;
  float: right;
  font-size: 14px;
}

.user-app-header .signup button {
  background: var(--green1);
  color: var(--buttoncolor);
  font-size: 1vw;
  text-wrap: nowrap;
  float: right;
}

@media only screen and (max-width: 600px) {
  .p {
    display: block;
  }

  .user-app-header .search-input input::placeholder {
    color: rgb(185, 184, 184);
    font-size: 6px;

  }

  .user-app-header .logo img {
    width: 100%;
  }

  .user-app-header .search-input img {
    padding: 2px;
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid black;
    margin-top: 4px;
    margin-right: 3px;
    width: 12px;
  }

  .user-app-header {
    display: none;
  }

  .Mobile {
    display: block;
  }

}