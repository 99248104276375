.signup-main {
    background-color: #46dd6b;
    height: 100vh;
    padding: 10% 15%;
}

.signup-main .signupPage {
    background-color: white;
    padding: 35px;
    border-radius: 10px;

}


.signup-main .signupPage .css-2613qy-menu {
    z-index: 2;
}