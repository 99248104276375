/* src/components/AboutUs.css */

.about-us {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    margin-bottom: 20px;
    color: #1ea698;
    font-size: 24px;
  }
  
  p {
    font-size: 16px;
    color: #333;
  }
  
  .video-section,
  .alumni-section,
  .roadmap-section,
  .testimonials-section {
    margin-bottom: 40px;
  }
  
  video {
    width: 100%;
    height: auto;
    border: 2px solid #1ea698;
    border-radius: 10px;
  }
  
  .alumni-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
  }
  
  .alumni-item {
    flex: 1 1 30%;
    text-align: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .alumni-item img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 2px solid #1ea698;
  }
  
  .roadmap {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .roadmap-item {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    border-left: 5px solid #1ea698;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .testimonials {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .testimonial-item {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    border-left: 5px solid #1ea698;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  