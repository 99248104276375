.chat-box {
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px -3px 15px rgb(194 191 191 / 63%);
  background-image: url('../../assets/11-01.png');
  background-size: 100%;

}

.chat-box::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('../../assets/11-01.png');
  opacity: 0.7;
  /* Adjust the opacity value as needed */
  z-index: -1;
  /* Ensure the pseudo-element stays behind the content */
}

.chat-box img {
  cursor: pointer;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.message {
  opacity: 0;
  margin: 5px 0;
  max-width: 80%;
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  animation: slideIn 0.5s forwards;
}

.message2 {
  align-self: flex-end;
  background-color: #1ea698;
  color: white;
  text-align: right;
  padding: 5px 20px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 5px 5px 12px #81818161;

}

.message.bot {
  align-self: flex-start;
  background-color: #f1f1f1;
  text-align: left;

  box-shadow: 5px 5px 12px #81818161;
}

.message.user {
  align-self: flex-end;
  background-color: #1ea698;
  color: white;
  text-align: right;
  padding: 5px 20px;
  
}

.message.user2 {
  align-self: flex-end;
  background-color: #007bff00;
  color: white;
  text-align: right;
  /* padding: 5px 20px; */
}

.sender {
  font-weight: bold;
  margin-bottom: 5px;
}

.input-area {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #fff;
}

.input-area input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-area button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.input-area button:hover {
  background-color: #0056b3;
}

.message input {
  width: calc(50% - 10px);
  margin: 5px 5px 5px 0;
}

.message button {
  margin: 5px 0;
}

.message .exp {
  display: flex;
  flex-wrap: wrap;
}

.message .item {
  background-color: #ccc;
  color: black;
  margin: 5px;
  padding: 5px 10px;
  border-radius: 20px;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}