.bharathidasnuniversity {
    position: relative;
    /* This is required to position the pseudo-element correctly */
    margin-top: 5%;
    padding: 20px;
    background-color: #f8f9fa;
    font-family: Arial, sans-serif;

    background-repeat: no-repeat;
    text-align: center;

}








.bharathidasnuniversity .bharathidasnuniversity-content .p-content {
    margin-top: 30px;
}

.intl-tel-input {
    width: 100%;
    /* margin-top: 20px; */
}

.bharathidasnuniversity input {
    margin-top: 0px;
}

.intl-tel-input input {

    width: 100%;
    height: 40px;
    border: 1px solid rgb(159, 154, 154);
    border-radius: 5px;
}

.bharathidasnuniversity .bharathidasnuniversity-content .p-content p {
    font-size: 25px;
}

.bharathidasnuniversity .bharathidasnuniversity-form {
    padding: 5px;
}

.bharathidasnuniversity .bharathidasnuniversity-form .bharathidasnuniversity-form-boby {
    padding: 20px;
    background-color: rgb(56, 54, 54);
    color: white;
    border-radius: 5px;
}

.bharathidasnuniversity .submit {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background-color: var(--green1);
    color: white;
}

.bharathidasnuniversity .exp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #efefef;
    padding: 24px 50px;
    border-radius: 5px;
    margin-top: 30px;
}

.bharathidasnuniversity .item {
    flex-basis: 20%;
    padding: 12px;
}

.bharathidasnuniversity .exp h3 {
    color: #FF270D;
    font-weight: 700;
}

.bharathidasnuniversity .exp p {
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.16px;
    font-weight: bold;
}

.bharathidasnuniversity .exp-courses {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 50px;
    border-radius: 5px;
    margin-top: 30px;
}

.bharathidasnuniversity .item-courses {
    flex-basis: 25%;
    padding: 15px;
}



.bharathidasnuniversity .item-courses h5 {
    height: 50px;
}

.bharathidasnuniversity .item-courses .courses-content {
    background-image: linear-gradient(to right, #1ea698 70%, rgb(29 192 192));
    padding: 10px;
    border-radius: 5px;
    color: white;
    text-align: center;
    box-shadow: 1px 1px 12px rgb(119, 117, 117);
    /* height: 150px; */
}

.bharathidasnuniversity .courses-content button {
    background-color: white;
    border: 1px white;
    color: black;
    border-radius: 3px;
}

.bharathidasnuniversity .cafiticate {
    /* background-color: rgba(222, 214, 214, 0.477); */
    display: flex;
    /* justify-content: center; */
    position: relative;
    /* padding-left: 10%; */
    padding: 20px;
    border-radius: 5px;
    background-image: url('../../assets/collegeView/Group\ 167.svg');
    height: 300px;
    background-size: 100%;
    background-repeat: no-repeat;
    color: white;
    overflow-y: hidden;


}

.bharathidasnuniversity .exp-pro {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #efefef;
    padding: 24px 50px;
    border-radius: 5px;
    margin-top: 30px;
}

@media only screen and (max-width: 600px) {
    .bharathidasnuniversity .bharathidasnuniversity-content .p-content p {
        font-size: 10px;
    }

    .bharathidasnuniversity .exp-courses {
        display: flex;
        flex-wrap: wrap;
        padding: 0px;
        border-radius: 5px;
        margin-top: 30px;
    }

    .bharathidasnuniversity .item-courses {
        flex-basis: 100%;
        padding: 12px;
    }

    .bharathidasnuniversity .item-courses h5 {
        height: 50px;
        font-size: 14px;
    }

    .bharathidasnuniversity {
        margin-top: 20%;
        padding: 5px;
    }

    .bharathidasnuniversity .item {
        flex-basis: 50%;
        padding: 12px;
    }

    .bharathidasnuniversity .cafiticate h1 {
        color: black !important;
        margin-top: 20px;
        font-size: 12px;
    }

    .bharathidasnuniversity .cafiticate {
        /* background-color: rgba(222, 214, 214, 0.477); */
        display: flex;
        /* justify-content: center; */
        position: relative;
        /* padding-left: 10%; */
        padding: 20px;
        border-radius: 5px;
        background-image: none;
        height: 300px;
        background-size: 100%;
        background-repeat: no-repeat;
        color: white;
    }

    .bharathidasnuniversity .exp {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: #efefef;
        padding: 9px;
        border-radius: 5px;
        margin-top: 30px;
    }
}