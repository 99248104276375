.liverpool.bodys {
    background-color: rgb(207, 205, 205);
}

.vit.headers {
    padding: 10px;
    background-color: #1ea698;
    display: flex;
    justify-content: center;
    color: white;
    position: fixed;
    width: 100%;
    z-index: 100;
}

.vit .vitbac {
    background-image: url('../../assets/Group.webp');
    background-size: 100%;
    background-repeat: no-repeatw;
}

.vit .info-item {
    flex: 1 1 23%;
    /* Makes sure each item takes about 23% of the width (4 items per row) */
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    height: 200px;
    /* Add a border for each item */
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
    /* Align text to the left */
}

.slick-arrow {
    display: none !important;
}

.vit .faq-item h4 {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
}

.vit .faq-item p {
    background-color: #dadada;
    padding: 15px;
    margin-top: -12px;
    font-size: 18px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

}

/* Style for strong text */
.vit .info-item strong {
    font-weight: bold;
    color: #333;
}

/* Optional: Make text smaller for better readability */
.vit .info-item {
    font-size: 14px;
}

/* Make the layout responsive */
@media screen and (max-width: 768px) {
    .info-item {
        flex: 1 1 48%;
        /* Two items per row for smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .info-item {
        flex: 1 1 100%;
        /* One item per row for very small screens */
    }
}


.vit.headers button {
    background-color: black;
    color: white;
    margin-left: 20px;
    border-radius: 30px;
    padding: 0px 20px;
}

.vit.bodys {
    padding: 20px 2%;
}

.vit.bodys h5 {
    font-size: 30px;
}

.vit.campaignimg button {
    background-color: #f6f7fb !important;
}

.vit.campaignimg img {
    border-radius: 10px;
}

.vit.card-title {
    font-size: 30px;
}

.vit.card-text {
    margin-top: 10px;
}

.vit.bodys h2 {
    font-size: 60px;
    color: #4B144B;
}

.vit.card:hover {
    background-color: #1ea698;
    color: white !important;
    cursor: pointer;
}

.vit.bodys h3 {
    font-size: 32px;
    color: #4B144B;
    position: relative;
    z-index: 1;
}

.vit.bodys .underline {
    background-color: black;
    padding: 2px;
    margin-top: 5%;
}

.card.active-card {
    border: 2px solid #1ea698;
    /* Blue border for active card */
    background-color: #1ea698;
    /* Light blue background */
    box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.2);
    /* Shadow effect */
    color: white;
}

.vithr {
    border: 2px solid #1ea698;
    margin: 10px 0px;
}

.vit.bodys .logo {
    width: 100%;
    margin-top: 10px;
}

.vit.bodys button {
    background-color: #1ea698;
    color: white;
    margin-top: 5%;
    padding: 10px;
    border-radius: 10px;
    border: none;
}

.vit.bodys .campaignimg {
    text-align: center;
    margin-top: 10px;
}

.vit.enquery .text {
    text-align: center;

}

.vit.enquery .form {
    margin-top: 2%;
    background-color: #1ea698;
    width: 45%;
    padding: 10px;
    border-radius: 10px;
    padding: 10px 5%;
}

.vit.enquery .form input {
    /* background-color: #1ea698; */
    border-color: black;
}

.vit.enquery .form button {
    background-color: white;
    padding: 10px;
    color: black;
    border-radius: 10px;
}

.vit.enquery .form-outer {
    display: flex;
    justify-content: center;
}

.vit.overview {
    margin: 3% 7%;
    border: 1px solid black;
    border-radius: 5px;
    padding: 15px;
    background-color: white;
}

.vit.borders {
    text-align: center;
    width: 100%;
    border-right: 2px solid black;
}

.vit.whyus {
    border: 1px solid black;
    padding: 10px;
    margin: 30px 5% 0px 40%;
    border-radius: 10px;
    text-align: center;
    height: 200px;
}

.vit.whyus2 {
    border: 1px solid black;
    padding: 10px;
    margin: 30px 40% 0px 5%;
    border-radius: 10px;
    text-align: center;
    height: 200px;
}

.vit.faq-outer {
    display: flex;
    justify-content: center;
}

.vit.faq {
    width: 50%;
}

.vit.faq .faq-q {
    display: flex;
    justify-content: space-between;
    padding: 10px 35px;
    background-color: #4ecccc;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
}

.vit.faq .faq-a {

    padding: 10px 35px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 20px;
}

.vit.placement {
    background-color: #1ea698;

}

.vit.placement .cop {
    padding: 10%;
    text-align: center;
}

.vit.placement .cop p {
    font-size: 20px;

}

.vit.placement .cop h1 {
    font-size: 70px;
}

.vit.liverpoolcity {
    /* background-image: url('../../assets/liverpoolu.webp'); */
    /* Commented out as requested */
    background-size: 100%;
    background-repeat: no-repeat;
    height: 1550px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.vit.liverpoolcity::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/liverpoolu.webp');
    background-size: cover;
    /* Cover the entire element */
    background-position: center;
    /* Center the image */
    opacity: 0.5;
    /* Set your desired opacity */
    z-index: 0;
    /* Set it behind the content */
}

.vit.days {
    background-color: #1ea698;
    /* Background color for the days div */
    padding: 10px;
    box-shadow: 5px 1px 12px #999999;
    height: 200px;
    overflow-y: hidden;
    border-radius: 5px;
    color: white;
    text-align: start;
    z-index: 1;
    /* Ensure it's on top of the background image */
    position: relative;
    /* Set position to relative to manage stacking */
}

.vit.specializations {
    display: flex;
    justify-content: center;
}

.vit.fontchange {
    font-size: 15px;
    font-style: italic;

}

.vit.day-text {
    text-align: center;
    z-index: 1;
    /* Ensure it's on top of the background image */
    position: relative;
    /* Set position to relative to manage stacking */
    color: #1ea698;
    font-size: 50px;
}


.vit.eligibility {
    background-color: #1ea698;
    color: white;
    padding: 0px 10%;
}



.vit.days h3 {
    font-size: 1rem;
}

.vit.eligibility p {
    color: white;
}

.vit.review {
    margin-top: 20px;
    padding: 10px 10%;
}

.vit.review .s_review {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    height: 400px;
    position: relative;
}

.vit.review .s_review .s_review_h {
    display: flex;
    padding: 10px 5%;
}

.vit.review .s_review .s_review_h .cricle {
    background-color: #5D5DFF;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-align: center;

}

.vit.review .s_review_s {
    padding: 10px 5%;
}

.vit.star {
    margin-left: 10px;
}

.vit.star .gh {
    color: gold;
}

.vit.s_review_b {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    margin-left: 20px;
}

.vit.floater {
    background-color: black;
    color: white;
    padding: 2% 10%;
}

.vit.floater .whatapp {
    float: right;
    background-color: #1ea698;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

.vit.whatappicon {

    bottom: 0;
    right: 0;
    position: fixed;
    margin-bottom: 7%;
    margin-right: 23px;


}

.vit.call {
    bottom: 0;
    right: 0;
    position: fixed;
    margin-bottom: 11%;
    margin-right: 23px;
    background-color: #5D5DFF;
    width: 50px;
    height: 50px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    color: white;

}

.vit.call img {
    width: 70px !important;
    margin-left: 10px;
}

.vit.whatappicon img {
    width: 50px;
    margin-left: 10px;
}

.vit.livepoolpara {
    background-color: #1ea698;
    padding: 5% 0px;
    color: white;
}

.vit.livepoolpara p {
    color: white;
}

@media only screen and (max-width: 600px) {
    .vit.headers button {
        font-size: 18px;
        padding: 0px;
        border-radius: 5px;
    }

    .vit.bodys {
        overflow: hidden;
        margin-top: 15%;
    }

    .vit.campaignimg img {
        width: 95% !important;
    }

    .vit.enquery .form {
        margin-top: 2%;
        background-color: #1ea698;
        width: 99%;
        padding: 10px;
        border-radius: 10px;
        padding: 10px 5%;
    }

    .vit.borders {
        text-align: center;
        width: 100%;
        border-top: 2px solid black;
        border-right: none;
        padding-top: 10px;
    }

    .vit.whyus {
        margin-top: 10px;
        border: 1px solid black;
        padding: 10px;
        margin: 20px 0px 0px 0px;
        border-radius: 10px;
        text-align: center;
        height: 200px;
    }

    .vit.whyus2 {
        margin: 20px 0px 0px 0px;
        border: 1px solid black;
        padding: 10px;

        border-radius: 10px;
        text-align: center;
        height: 200px;
    }

    .vit.faq {
        width: 99%;

    }

    .vit.review {
        margin-top: 20px;
        padding: 11px 2%;
    }

    .vit.whatappicon {
        bottom: 0;
        right: 0;
        position: fixed;
        margin-bottom: 32%;
        margin-right: 23px;
    }

    .vit.call {
        bottom: 0;
        right: 0;
        position: fixed;
        margin-bottom: 53%;
    }

    .vit.jk {
        border-top: 2px solid;
        text-align: center;
    }

    .vit.bodys h2 {
        font-size: 27px;
        color: #4B144B;
    }

    .vit.bodys h3 {
        font-size: 20px;
        color: #4B144B;
    }

}