.CollegeView .col-view-content {
    margin-top: 10%;
    padding: 10px 10%;
    color: #E4B449;
}

.CollegeView .col-view-content-2 {
    margin-top: 2%;
    padding: 10px 10%;
    color: #E4B449;
}

.CollegeView .col-view-content b {
    color: black;
}

.col-view-content .btn-col-view button {
    background-color: var(--green1);
    border-radius: 50px;
    padding: 10px 30px;
    color: white;
    border: 3px solid white;
    box-shadow: 9px 10px 23.1px 0px #00000040;
    margin-top: 20px;

}

.con-view-college {
    background-image: url('../../assets/collegeView/Rectangle\ 2007.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    height: 600px;
    margin-top: 20px;
}

.con-view-college img {
    width: 100%;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    height: 500px;
    margin-left: 30px;
    margin-top: -30px;
}

.col-view-content-2 .b-card {
    width: 100%;
    border: 1px solid #1EA698;
    box-shadow: 4px 4px 9.7px 0px #00000040;
    /* padding: 10px; */
    border-radius: 10px;

}

.col-view-content-2 .b-card .top-content {
    padding: 20px 40px;
    background-color: #1EA698;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    margin-bottom: 20px;

}

.col-view-content-2 .b-card .top-card small {
    margin-left: 20px;
    border: 1px solid white;
    border-radius: 50px;
    padding: 5px 10px;
}

.col-view-content-2 .b-card .top-card {
    margin-top: 0px;
}

.col-view-content-2 .vb {
    color: black;
    padding: 20px 40px;
}

.col-view-content-2 .btn-p {
    display: flex;
    justify-content: end;
}

.col-view-content-2 .btn-p .Brochure {
    background-color: #1EA698;
    color: white;
    border-radius: 50px;
    padding: 1px 10px;
    border: 3px solid white;
    width: 100%;

}

.col-view-content-2 .btn-p .Compare {
    background-color: #F6F7FB;
    color: #1EA698;
    border-radius: 50px;
    padding: 1px 10px;
    border: 1px solid #1EA698;
    width: 100%;

}

.col-view-content-2 .btn-p img {
    width: 15px;
    margin-right: 5px;
}

.CollegeView .viewmore {
    text-align: center;
    margin-top: 30px;
}

.CollegeView .viewmore button {
    background-color: #F6F7FB;
    color: #1EA698;
    border: 1px solid #1EA698;
    padding: 5px 20px;
    border-radius: 50px;
}

.CollegeView .c-body-card {
    width: 380px;
    padding: 12px;
    border-radius: 5px;
    height: 300px;
    border: 2pxs solid black;
    background-color: white;
    margin-left: 20px;
}

.CollegeView .btn-pre-next {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 87%;
    margin-left: -3%;
    margin-top: 9%;
}

.CollegeView .btn-pre-next .prev-btn {
    background-color: #1EA698;
    border-radius: 50%;
    color: white;
    padding: 10px;
    border: none;
    width: 50px;
}

.CollegeView .cetificate {
    background-image: url("../../assets/collegeView/Group\ 167.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    /* height: 300px; */
    margin-top: 10%;
    padding: 10px 10%;
}

@media only screen and (max-width: 600px) {
    .con-view-college img {
        width: 100%;
        border-top-left-radius: 21px;
        border-bottom-left-radius: 21px;
        height: 113px;
        margin-left: 11px;
        margin-top: -30px;
    }

    .CollegeView h1 {
        font-size: 7px;
    }

    .col-view-content .btn-col-view button {
        background-color: var(--green1);
        border-radius: 50px;
        padding: 0px 12px;
        color: white;
        border: 2px solid white;
        box-shadow: 9px 10px 23.1px 0px #00000040;
        margin-top: 4px;
        font-size: 12px;
    }

    .con-view-college {

        background-size: 100%;
        background-repeat: no-repeat;
        height: 135px;
        margin-top: 20px;
    }

    .b-card h2,
    small,
    p,
    b {
        font-size: 17px;
    }

    .b-card {
        font-size: 7px;
    }

    .col-view-content-2 .b-card .top-content {
        padding: 7px 14px;
        background-color: #1EA698;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: white;
        margin-bottom: 20px;
    }

    .col-view-content-2 .vb {
        color: black;
        padding: 3px 16px;
    }

    .col-view-content-2 .btn-p .Compare {
        background-color: #F6F7FB;
        color: #1EA698;
        border-radius: 50px;
        padding: 1px 10px;
        border: 1px solid #1EA698;
        width: 100%;
    }

    .col-view-content-2 .btn-p .Brochure {
        background-color: #1EA698;
        color: white;
        border-radius: 50px;
        padding: 1px 10px;
        border: 3px solid white;
        width: 100%;
    }

    .col-view-content-2 .btn-p .Brochure img {
        display: none;
    }

    .CollegeView .col-view-content-2 {
        margin-top: 2%;
        padding: 10px 3%;
        color: #E4B449;
    }
}