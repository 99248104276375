/* suggestions.css */

.suggestions {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
}

.sug_main {
    background-image: url('../../assets/Logo/1256875_6530\ \(1\).jpg');
    position: absolute;
    width: 100%;
    background-size: 100%;
}

.suggestions img {
    width: 150px;
    margin-bottom: 20px;
}

h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

.form-group {
    text-align: left;
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

.form-control {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: 0.3s;
}

.form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.is-invalid {
    border-color: #dc3545;
}

.error-text {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 5px;
}

.btn {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.btn:hover {
    background-color: #0056b3;
}

.success-message {
    color: #28a745;
    font-weight: bold;
    margin-top: 10px;
}

.thank-you-message {
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
    text-align: center;
}

/* OTP Input Styles */
.otp-input {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.otp-field {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: 0.3s;
}

.otp-field:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.otp-field:invalid {
    border-color: #dc3545;
}

.otp-field:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .suggestions {
        width: 90%;
        padding: 15px;
    }

    .sug_main {
        background-image: none !important;
        position: absolute;
        width: 100%;
        background-size: 100%;
    }
}