bodys {
    background-color: rgb(207, 205, 205);
}

.headers {
    padding: 10px;
    background-color: #1ea698;
    display: flex;
    justify-content: center;
    color: white;
    position: fixed;
    width: 100%;
    z-index: 100;
}

.headers button {
    background-color: black;
    color: white;
    margin-left: 20px;
    border-radius: 30px;
    padding: 0px 20px;
}

.bodys {
    padding: 20px 5%;
}

.bodys h5 {
    font-size: 30px;
}

.bodys h2 {
    font-size: 60px;
    color: #4B144B;
}

.bodys h3 {
    font-size: 40px;
    color: #4B144B;
}

.bodys .underline {
    background-color: black;
    padding: 2px;
    margin-top: 5%;
}

.bodys .logo {
    width: 20%;
}

.bodys button {
    background-color: #1ea698;
    color: white;
    margin-top: 5%;
    padding: 10px;
    border-radius: 10px;
    border: none;
}

.bodys .campaignimg {
    text-align: center;
    margin-top: 10px;
}

.enquery .text {
    text-align: center;

}

.enquery .form {
    margin-top: 2%;
    background-color: #1ea698;
    width: 45%;
    padding: 10px;
    border-radius: 10px;
    padding: 10px 5%;
}

.enquery .form input {
    /* background-color: #1ea698; */
    border-color: black;
}

.enquery .form button {
    background-color: white;
    padding: 10px;
    color: black;
    border-radius: 10px;
}

.enquery .form-outer {
    display: flex;
    justify-content: center;
}

.overview {
    margin: 3% 7%;
    border: 1px solid black;
    border-radius: 5px;
    padding: 15px;
    background-color: white;
}

.borders {
    text-align: center;
    width: 100%;
    border-right: 2px solid black;
}

.whyus {
    border: 1px solid black;
    padding: 10px;
    margin: 30px 5% 0px 40%;
    border-radius: 10px;
    text-align: center;
    height: 200px;
}

.whyus2 {
    border: 1px solid black;
    padding: 10px;
    margin: 30px 40% 0px 5%;
    border-radius: 10px;
    text-align: center;
    height: 200px;
}

.faq-outer {
    display: flex;
    justify-content: center;
}

.faq {
    width: 50%;
}

.faq .faq-q {
    display: flex;
    justify-content: space-between;
    padding: 10px 35px;
    background-color: #4ecccc;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
}

.faq .faq-a {

    padding: 10px 35px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 20px;
}

.placement {
    background-color: #1ea698;

}

.placement .cop {
    padding: 10%;
    text-align: center;
}

.placement .cop p {
    font-size: 20px;

}

.placement .cop h1 {
    font-size: 70px;
}

.review {
    margin-top: 20px;
    padding: 10px 15%;
}

.review .s_review {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    height: 400px;
    position: relative;
}

.review .s_review .s_review_h {
    display: flex;
    padding: 10px 5%;
}

.review .s_review .s_review_h .cricle {
    background-color: #5D5DFF;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-align: center;

}

.review .s_review_s {
    padding: 10px 5%;
}

.star {
    margin-left: 10px;
}

.star .gh {
    color: gold;
}

.s_review_b {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    margin-left: 20px;
}

.floater {
    background-color: black;
    color: white;
    padding: 2% 10%;
}

.floater .whatapp {
    float: right;
    background-color: #1ea698;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

.whatappicon {

    bottom: 0;
    right: 0;
    position: fixed;
    margin-bottom: 7%;
    margin-right: 23px;


}

.call {
    bottom: 0;
    right: 0;
    position: fixed;
    margin-bottom: 11%;
    margin-right: 23px;
    background-color: #5D5DFF;
    width: 50px;
    height: 50px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    color: white;

}

.call img {
    width: 70px !important;
    margin-left: 10px;
}

.whatappicon img {
    width: 50px;
    margin-left: 10px;
}

@media only screen and (max-width: 600px) {
    .headers button {
        font-size: 18px;
        padding: 0px;
        border-radius: 5px;
    }

    .bodys {
        overflow: hidden;
        margin-top: 15%;
    }

    .campaignimg img {
        width: 95% !important;
    }

    .enquery .form {
        margin-top: 2%;
        background-color: #1ea698;
        width: 99%;
        padding: 10px;
        border-radius: 10px;
        padding: 10px 5%;
    }

    .borders {
        text-align: center;
        width: 100%;
        border-top: 2px solid black;
        border-right: none;
        padding-top: 10px;
    }

    .whyus {
        margin-top: 10px;
        border: 1px solid black;
        padding: 10px;
        margin: 20px 0px 0px 0px;
        border-radius: 10px;
        text-align: center;
        height: 200px;
    }

    .whyus2 {
        margin: 20px 0px 0px 0px;
        border: 1px solid black;
        padding: 10px;

        border-radius: 10px;
        text-align: center;
        height: 200px;
    }

    .faq {
        width: 99%;

    }

    .review {
        margin-top: 20px;
        padding: 11px 2%;
    }

    .whatappicon {
        bottom: 0;
        right: 0;
        position: fixed;
        margin-bottom: 32%;
        margin-right: 23px;
    }

    .call {
        bottom: 0;
        right: 0;
        position: fixed;
        margin-bottom: 53%;
    }

    .jk {
        border-top: 2px solid;
        text-align: center;
    }

}