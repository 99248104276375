@keyframes slideRight {
  0% {
    left: 0;
  }

  100% {
    left: calc(100% - 100px);
    /* Adjust 100px according to your image width */
  }
}

/* .messagecontent::before {
  content: "";
  width: 41px;
  height: 30px;
  background-color: #1ea6988c;
  transform: rotate(20deg);
  position: 1px;
  bottom: -5px;
  right: -5px;
  position: absolute;
  border-top-left-radius: 10px;
  border-top-right-radius: 40px;
  border-top-left-radius: 10px;
  border-top-left-radius: 10px;
} */

.whatappus {
  width: 10%;
}

.distop {
  display: block;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(-50%) translateY(0);
  }

  40% {
    transform: translateX(-50%) translateY(-20px);
  }

  60% {
    transform: translateX(-50%) translateY(-7px);
  }
}

@keyframes popupAnimation {
  0% {
    transform: translate(-5%, 100%);
    /* opacity: 0; */
  }

  100% {
    transform: translate(-5%, 0%);
    /* opacity: 1; */
  }
}

.mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .distop {
    display: none !important;
  }

  .mobile {
    display: block;
  }
}