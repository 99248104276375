.liverpool.bodys {
    background-color: rgb(207, 205, 205);
}

.liverpool .headers {
    padding: 10px;
    background-color: #1ea698;
    display: flex;
    justify-content: center;
    color: white;
    position: fixed;
    width: 100%;
    z-index: 100;
}


.liverpool .headers button {
    background-color: black;
    color: white;
    margin-left: 20px;
    border-radius: 30px;
    padding: 0px 20px;
}

.liverpool .bodys {
    padding: 20px 2%;
}

.liverpool .bodys h5 {
    font-size: 30px;
}

.liverpool .campaignimg button {
    background-color: #f6f7fb !important;
}

.liverpool .campaignimg img {
    border-radius: 10px;
}

.liverpool .card-title {
    font-size: 30px;
}

.liverpool .card-text {
    margin-top: 10px;
}

.liverpool .bodys h2 {
    font-size: 60px;
    color: #4B144B;
}

.liverpool .card:hover {
    background-color: #1ea698;
    color: white !important;
    cursor: pointer;
}

.liverpool .bodys h3 {
    font-size: 32px;
    color: #4B144B;
    position: relative;
    z-index: 1;
}

.liverpool .bodys .underline {
    background-color: black;
    padding: 2px;
    margin-top: 5%;
}

.card.active-card {
    border: 2px solid #1ea698;
    /* Blue border for active card */
    background-color: #1ea698;
    /* Light blue background */
    box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.2);
    /* Shadow effect */
    color: white;
}

.liverpool hr {
    border: 2px solid #1ea698;
    margin: 10px 0px;
}

.liverpool .bodys .logo {
    width: 30%;
    margin-top: 10px;
}

.liverpool .bodys button {
    background-color: #1ea698;
    color: white;
    margin-top: 5%;
    padding: 10px;
    border-radius: 10px;
    border: none;
}

.liverpool .bodys .campaignimg {
    text-align: center;
    margin-top: 10px;
}

.liverpool .enquery .text {
    text-align: center;

}

.liverpool .enquery .form {
    margin-top: 2%;
    background-color: #1ea698;
    width: 45%;
    padding: 10px;
    border-radius: 10px;
    padding: 10px 5%;
}

.liverpool .enquery .form input {
    /* background-color: #1ea698; */
    border-color: black;
}

.liverpool .enquery .form button {
    background-color: white;
    padding: 10px;
    color: black;
    border-radius: 10px;
}

.liverpool .enquery .form-outer {
    display: flex;
    justify-content: center;
}

.liverpool .overview {
    margin: 3% 7%;
    border: 1px solid black;
    border-radius: 5px;
    padding: 15px;
    background-color: white;
}

.liverpool .borders {
    text-align: center;
    width: 100%;
    border-right: 2px solid black;
}

.liverpool .whyus {
    border: 1px solid black;
    padding: 10px;
    margin: 30px 5% 0px 40%;
    border-radius: 10px;
    text-align: center;
    height: 200px;
}

.liverpool .whyus2 {
    border: 1px solid black;
    padding: 10px;
    margin: 30px 40% 0px 5%;
    border-radius: 10px;
    text-align: center;
    height: 200px;
}

.liverpool .faq-outer {
    display: flex;
    justify-content: center;
}

.liverpool .faq {
    width: 50%;
}

.liverpool .faq .faq-q {
    display: flex;
    justify-content: space-between;
    padding: 10px 35px;
    background-color: #4ecccc;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
}

.liverpool .faq .faq-a {

    padding: 10px 35px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 20px;
}

.liverpool .placement {
    background-color: #1ea698;

}

.liverpool .placement .cop {
    padding: 10%;
    text-align: center;
}

.liverpool .placement .cop p {
    font-size: 20px;

}

.liverpool .placement .cop h1 {
    font-size: 70px;
}

.liverpool .liverpoolcity {
    /* background-image: url('../../assets/liverpoolu.webp'); */
    /* Commented out as requested */
    background-size: 100%;
    background-repeat: no-repeat;
    height: 1550px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.liverpool .liverpoolcity::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/liverpoolu.webp');
    background-size: cover;
    /* Cover the entire element */
    background-position: center;
    /* Center the image */
    opacity: 0.5;
    /* Set your desired opacity */
    z-index: 0;
    /* Set it behind the content */
}

.liverpool .days {
    background-color: #1ea698;
    /* Background color for the days div */
    padding: 10px;
    box-shadow: 5px 1px 12px #999999;
    height: 200px;
    overflow-y: hidden;
    border-radius: 5px;
    color: white;
    text-align: start;
    z-index: 1;
    /* Ensure it's on top of the background image */
    position: relative;
    /* Set position to relative to manage stacking */
}

.liverpool .specializations {
    display: flex;
    justify-content: center;
}

.liverpool .fontchange {
    font-size: 15px;
    font-style: italic;

}

.liverpool .day-text {
    text-align: center;
    z-index: 1;
    /* Ensure it's on top of the background image */
    position: relative;
    /* Set position to relative to manage stacking */
    color: #1ea698;
    font-size: 50px;
}


.liverpool .eligibility {
    background-color: #1ea698;
    color: white;
    padding: 0px 10%;
}



.liverpool .days h3 {
    font-size: 1rem;
}

.liverpool .eligibility p {
    color: white;
}

.liverpool .review {
    margin-top: 20px;
    padding: 10px 10%;
}

.liverpool .review .s_review {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    height: 400px;
    position: relative;
}

.liverpool .review .s_review .s_review_h {
    display: flex;
    padding: 10px 5%;
}

.liverpool .review .s_review .s_review_h .cricle {
    background-color: #5D5DFF;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-align: center;

}

.liverpool .review .s_review_s {
    padding: 10px 5%;
}

.liverpool .star {
    margin-left: 10px;
}

.liverpool .star .gh {
    color: gold;
}

.liverpool .s_review_b {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    margin-left: 20px;
}

.liverpool .floater {
    background-color: black;
    color: white;
    padding: 2% 10%;
}

.liverpool .floater .whatapp {
    float: right;
    background-color: #1ea698;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

.liverpool .whatappicon {

    bottom: 0;
    right: 0;
    position: fixed;
    margin-bottom: 7%;
    margin-right: 23px;


}

.liverpool .call {
    bottom: 0;
    right: 0;
    position: fixed;
    margin-bottom: 11%;
    margin-right: 23px;
    background-color: #5D5DFF;
    width: 50px;
    height: 50px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    color: white;

}

.liverpool .call img {
    width: 70px !important;
    margin-left: 10px;
}

.liverpool .whatappicon img {
    width: 50px;
    margin-left: 10px;
}

.liverpool .livepoolpara {
    background-color: #1ea698;
    padding: 5% 0px;
    color: white;
}

.liverpool .livepoolpara p {
    color: white;
}

@media only screen and (max-width: 600px) {
    .liverpool .headers button {
        font-size: 18px;
        padding: 0px;
        border-radius: 5px;
    }

    .liverpool .bodys {
        overflow: hidden;
        margin-top: 15%;
    }

    .liverpool .campaignimg img {
        width: 95% !important;
    }

    .liverpool .enquery .form {
        margin-top: 2%;
        background-color: #1ea698;
        width: 99%;
        padding: 10px;
        border-radius: 10px;
        padding: 10px 5%;
    }

    .liverpool .borders {
        text-align: center;
        width: 100%;
        border-top: 2px solid black;
        border-right: none;
        padding-top: 10px;
    }

    .liverpool .whyus {
        margin-top: 10px;
        border: 1px solid black;
        padding: 10px;
        margin: 20px 0px 0px 0px;
        border-radius: 10px;
        text-align: center;
        height: 200px;
    }

    .liverpool .whyus2 {
        margin: 20px 0px 0px 0px;
        border: 1px solid black;
        padding: 10px;

        border-radius: 10px;
        text-align: center;
        height: 200px;
    }

    .liverpool .faq {
        width: 99%;

    }

    .liverpool .review {
        margin-top: 20px;
        padding: 11px 2%;
    }

    .liverpool .whatappicon {
        bottom: 0;
        right: 0;
        position: fixed;
        margin-bottom: 32%;
        margin-right: 23px;
    }

    .liverpool .call {
        bottom: 0;
        right: 0;
        position: fixed;
        margin-bottom: 53%;
    }

    .liverpool .jk {
        border-top: 2px solid;
        text-align: center;
    }

    .liverpool .bodys h2 {
        font-size: 27px;
        color: #4B144B;
    }

    .liverpool .bodys h3 {
        font-size: 20px;
        color: #4B144B;
    }

}