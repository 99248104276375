.timeline {
    font-family: 'Poppins', sans-serif;
    color: #333;
    padding: 20px;
  }
  
  .timeline__controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .timeline__controls button {
    background-color: #f7c524;
    border: none;
    padding: 10px 15px;
    margin: 0 10px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .timeline__controls button:hover {
    background-color: #e6b200;
  }
  
  .timeline__container {
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 20px;
  }
  
  .timeline__list {
    display: inline-flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .timeline__item {
    padding: 10px 20px;
    cursor: pointer;
    position: relative;
  }
  
  .timeline__item::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #f7c524;
    transition: width 0.3s ease, left 0.3s ease;
  }
  
  .timeline__item:hover::before,
  .timeline__item--active::before {
    width: 100%;
    left: 0;
  }
  
  .timeline__year {
    font-size: 16px;
    white-space: nowrap;
  }
  
  .timeline__content {
    text-align: center;
  }
  
  .timeline__content h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .timeline__content p {
    font-size: 18px;
  }
  