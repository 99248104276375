/* ExploreModual.css */

.exploremodual-app-header {
    background-color: white;
    width: 60%;
    position: absolute;
    margin-top: 43px;
    border-radius: 6px;
    z-index: 7;
}

.exploremodual-app-header .bss {
    border: 1px solid var(--green1);
    padding: 17px;
    background-color: rgba(237, 245, 244, 1);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.exploremodual-app-header .row {
    --bs-gutter-x: 11.5rem;
}

.exploremodual-app-header hr {
    margin-top: 7px;
    margin-bottom: 7px;
}

.exploremodual-app-header .hhj {
    border-top: 1px solid var(--green1);
    border-right: 1px solid var(--green1);
    border-bottom: 1px solid var(--green1);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;

}

.app-button,
.app-button-uni {
    width: 100%;
    font-size: 12px;
    background-color: white;
    background-color: rgba(237, 245, 244, 1);
    color: black;
    text-align: start;
    padding: 15px;
    cursor: pointer;
}


.app-button span {
    display: block;
    /* Ensure span takes full width */
}

.app-button-unis {
    display: flex;
    padding: 10px;
    margin-top: 6px;
    height: 150px;
    border-bottom: 2px solid rgb(182, 177, 177);
    /* border-radius: 10px; */

}



.univercity .university-content-ex {
    padding: 10px;
    width: 100%;


}

.univercity .btn-y {
    display: flex;
    justify-content: space-between;
}



.univercity .university-content-ex .btn-y .Brochure {
    background-color: var(--green1);
    border-radius: 5px;
    color: white;
    width: 27%;
    font-size: 10px;

}

.univercity .university-content-ex .btn-y .Brochure:hover {
    background-color: rgb(22, 97, 14);


}

.univercity .university-content-ex .btn-y .register {
    background-color: rgb(196, 144, 144);
    border-radius: 5px;
    color: white;
    width: 27%;
    font-size: 10px;

}

.univercity .university-content-ex .btn-y .register:hover {
    background-color: rgb(129, 70, 70);


}

.univercity,
.couses {
    padding: 0 10px;
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.exploremodual-app-header .active {
    background-color: var(--green1);
    color: white;
    border-radius: 50px;
    border: 5px solid white;
}

.c {
    display: flex;
}