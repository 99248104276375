.aboutPage {
  font-family: 'Poppins', sans-serif;
  color: #333;
  margin-top: 5%;
}

/* Header Section */
.aboutPage__header {
  display: flex;
  padding: 60px;
  background-color: #f5f5f5;
}

.aboutPage__header-video img {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
}

.aboutPage__header-content h1 {
  font-size: 48px;
  font-weight: bold;
  color: #333;
  margin-left: 20px;
}

/* Mission Section */
.aboutPage__mission {
  padding: 40px;
  background-color: #f7f7f7;
  text-align: left;
}

.aboutPage__mission h2 {
  font-size: 36px;
  color: #F7C524;
  margin-bottom: 20px;
}

.aboutPage__mission p {
  font-size: 18px;
  line-height: 1.6;
}

/* Journey Section */
.aboutPage__journey {
  padding: 40px;
  background-color: #f1f1f1;
  text-align: center;
}

.aboutPage__journey h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.aboutPage__journey p {
  font-size: 18px;
}

/* Students Say Section */
.aboutPage__students {
  padding: 40px;
  text-align: center;
}

.aboutPage__students h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.aboutPage__students-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.aboutPage__students-controls img {
  width: 50px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.aboutPage__students-controls img:hover {
  transform: scale(1.1);
}

.aboutPage__students-cards {
  display: flex;
  justify-content: center;
}

.aboutPage__students-card {
  max-width: 400px;
  background-color: white;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.aboutPage__students-card p {
  font-size: 16px;
}

.aboutPage__students-card-img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.aboutPage__students-card-name {
  font-weight: bold;
  margin-top: 10px;
}

.aboutPage__students-card-title {
  font-style: italic;
}

/* CTA Section */
.aboutPage__cta {
  background-color: #333;
  color: white;
  padding: 60px 40px;
  text-align: center;
}

.aboutPage__cta h2 {
  font-size: 30px;
  margin-bottom: 30px;
}

.aboutPage__cta-button {
  background-color: #F7C524;
  color: #333;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.aboutPage__cta-button:hover {
  background-color: #e6b100;
}
/* Partners Section */
.aboutPage__partners {
  text-align: center;
  padding: 40px 20px;
  background-color: #f1f1f1;
  margin-bottom: 40px;
  border-radius: 10px;
}

.aboutPage__partners h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.aboutPage__partners p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 30px;
  color: #555;
}

.partners__logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.partners__logos img {
  max-width: 150px;
  max-height: 80px;
  transition: transform 0.3s ease-in-out;
}

.partners__logos img:hover {
  transform: scale(1.1);
}
.aboutPage__success-stories {
  margin: 40px 0;
  padding: 20px;
  background-color: #f5f7fa; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners */
}

.aboutPage__success-stories h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333; /* Dark text for better contrast */
}

.aboutPage__success-stories p {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.1rem;
  color: #555; /* Slightly lighter color for text */
}

.aboutPage__success-stories .success-stories__scrollable-container {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  padding: 10px 0; /* Vertical padding for spacing */
  scrollbar-width: thin; /* Firefox scrollbar styling */
  scrollbar-color: #aaa #f5f7fa; /* Scrollbar colors for Firefox */
}

/* Custom scrollbar styles for Webkit browsers */
.aboutPage__success-stories .success-stories__scrollable-container::-webkit-scrollbar {
  height: 8px; /* Height of the horizontal scrollbar */
}

.aboutPage__success-stories .success-stories__scrollable-container::-webkit-scrollbar-thumb {
  background-color: #aaa; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded scrollbar thumb */
}

.aboutPage__success-stories .success-stories__scrollable-container::-webkit-scrollbar-track {
  background: #f5f7fa; /* Color of the scrollbar track */
}

.aboutPage__success-stories .success-stories__scrollable-container .success-stories__list {
  display: flex; /* Flexbox for horizontal layout */
}

.aboutPage__success-stories .success-stories__scrollable-container .success-story__card {
  flex: 0 0 auto; /* Prevent shrinking, allow growing */
  width: 275px; /* Fixed width for each card */
  background-color: #555; /* Card background color */
  margin: 10px; /* Margin around each card */
  color: white; /* Text color */
  border-radius: 5px; /* Rounded corners */
  padding: 20px; /* Inner padding for content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}
.aboutPage__success-stories .success-story__card p{
  color: white;
}
.aboutPage__success-stories .success-stories__scrollable-container .success-story__card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.aboutPage__success-stories .success-story__card h3 {
  font-size: 1.25rem; /* Heading size */
  margin-bottom: 10px; /* Spacing below the heading */
}

.aboutPage__success-stories .success-story__card p {
  font-size: 1rem; /* Paragraph size */
  line-height: 1.5; /* Better line height for readability */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .aboutPage__success-stories {
      padding: 10px; /* Padding adjustment for smaller screens */
  }

  .aboutPage__success-stories .success-story__card {
      width: 150px; /* Adjust width for smaller screens */
  }
}
.aboutPage__courses {
  padding: 40px 20px; /* Padding around the section */
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 10px; /* Rounded corners */

  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: hidden; /* Hide vertical overflow */
}

.aboutPage__courses h2 {
  font-size: 2rem; /* Font size for headings */
  margin-bottom: 20px; /* Space below heading */
  color: #333; /* Dark text color */
  text-align: center; /* Center align heading */
}

.aboutPage__courses p {
  font-size: 1.1rem; /* Font size for description */
  margin-bottom: 30px; /* Space below the paragraph */
  color: #555; /* Medium grey for readability */
  text-align: center; /* Center align paragraph */
}

.programs__container {
  display: flex; /* Use flexbox for layout */
  overflow-x: auto; /* Enable horizontal scrolling */

  width: 100%;
}

.program__card {
  background-color: #fff; /* White background for cards */
  border-radius: 10px; /* Rounded corners for cards */
  padding: 20px; /* Padding inside the cards */
  width: 475px; /* Fixed width for cards */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
  margin: 10px; /* Space around each card */
}

.program__card:hover {
  transform: translateY(-5px); /* Lift card on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Increase shadow on hover */
}

.program__card h3 {
  font-size: 1.5rem; /* Font size for program title */
  margin-bottom: 10px; /* Space below title */
  color: #333; /* Dark color for title */
  text-wrap: nowrap;
}

.program__card ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.program__card ul li {
  font-size: 1rem; /* Font size for list items */
  color: #666; /* Medium grey for list items */
  padding: 5px 0; /* Space between list items */
}

/* Responsive design */
@media (max-width: 768px) {
  .program__card {
      flex: 0 0 45%; /* Two cards per row on tablets */
      max-width: 45%; /* Max width for cards */
  }
}

@media (max-width: 480px) {
  .program__card {
      flex: 0 0 100%; /* Full width on mobile */
      max-width: 100%; /* Max width for cards */
  }
}
