.landingpage {
    margin-top: 2.2%;
}

.landingpage .pd {
    padding: 0px 8%;
}

.landingpage .if_uni {
    background-color: #b4b4b4;
    height: 350px;
    margin-top: 10%;
    position: relative;
    margin-right: -20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    animation: slideIn 1s ease-in-out forwards;
    /* Apply the animation */
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
        /* Start from below */
        opacity: 0;
        /* Start invisible */
    }

    to {
        transform: translateY(0);
        /* End at original position */
        opacity: 1;
        /* Fade in */
    }
}



.landingpage .top-img img {
    object-fit: cover;
    height: 500px;
    border-radius: 70px;
    margin-bottom: 10px;
    box-shadow: 13px 3px 12px #afa8a8;
    ;

}

.landingpage .content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.landingpage .content .content-body {
    text-align: start;
}

.landingpage .content .content-body .h-1 {
    font-size: 50px;
    color: #ffffff;
}

.landingpage .content .content-body .h-2 {
    color: black;
    font-weight: 900;
    font-family: Jost;
    font-size: 60px;
    font-style: italic;
    line-height: 84px;
    text-align: left;
    /* text-shadow: 1px 0 0 #ccc, 2px 0 0 #ccc, 3px 0 0 #ccc, 4px 0 0 #ccc, 5px 0 0 #ccc, 6px 0 0 #ccc, 7px 0 0 #ccc, 8px 0 0 #ccc, 9px 0 0 #ccc, 10px 0 0 #ccc, 11px 0 0 #ccc, 12px 0 0 #ccc, 20px 0 30px rgba(0, 0, 0, .5); */

}

.landingpage .funstore {
    margin: 10px 10%;
    text-align: center;
    border: 1px solid black;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 7px 11px 12px #0000005c;
}

.landingpage .funstore .num {
    color: #ff7f00;
    font-weight: 800;
}

.landingpage .funstore .con {
    font-weight: 700;
}

.landingpage .header-info {
    margin-top: 2%;
}

.landingpage .header-info hr {
    margin-top: 10px;
    border: 3px solid black;
}

.landingpage .cardpoint {
    width: 80%;
    margin: 10%;
    position: relative;
    background-color: white;
    border-radius: 10px;
    height: 450px;
}

.landingpage .con-ccc {
    background-color: white;
    padding: 12px 10px;
    text-wrap: nowrap;
    overflow-x: hidden;
    text-align: center;
}

.landingpage .bottom_button {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.landingpage .cardpoint:hover {
    transform: translateY(-10px);
    /* Move the card up */
    box-shadow: 11px 0px 12px #0000005e;
}

.landingpage .cardpoint button {
    width: 100%;
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.landingpage .logo-clg {
    position: absolute;
    background-color: white;
    width: 100px;
    top: 0;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px
}

.landingpage .logoImage img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}




/* Fade and slide-in animation for the content */
.showview-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.4s ease, max-height 0.4s ease;
}

.showview-content.show {
    opacity: 1;
    max-height: 1000px;
    /* Set a maximum height that fits your content */
}

.landingpage .form-tem {
    background-color: #1ea698;
    padding: 20px;
}

.landingpage .btn-enroll {
    background-color: white;
    width: 60%;
    color: black;
}

.landingpage .form-enroll {
    background-color: #f0ebeb;
    padding: 10%;
    border-radius: 5px;
    box-shadow: 9px 7px 20px #b4b4b4;
    border: 1px solid;

}

.landingpage .form-tem .text {
    color: white
}

.landingpage .form-tem img {
    width: 70%;
}

.landingpage .slider {
    display: flex;
    overflow: hidden;
    width: 100%;
    padding: 2.5rem;
    white-space: nowrap;
    position: relative;
}

.landingpage .slider .track {
    display: flex;
    animation: scroll 20s linear infinite;
}

.landingpage .slider .item {
    flex: 0 0 auto;
    /* Prevent items from shrinking */
    margin-right: 3%;
}

.landingpage .slider .item img {
    width: 120px;
}

/* Keyframes for seamless auto-scroll */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.landingpage .info_path img {
    width: 100%;
}

.pathway {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.pathway img {
    width: 100%;
}

.landingpage .slick-dots {
    bottom: 0px !important;
}

.landingpage .profile_info {
    display: flex;
    padding: 0px 20px;
}

.landingpage .profile_info img {
    width: 50px !important;
    height: 50px;
    object-fit: cover;
    /* Ensures the image covers the area */
    border-radius: 50%;
}

.landingpage .profile_info p {
    margin: 0px !important;
    width: 800;
}


.landingpage .profile_info .into_text {
    margin-left: 20px;
}

.landingpage .card_info .row {
    background-color: white;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    /* Optional: Adds rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: Adds subtle shadow */
}

.landingpage .card_info .row h2 {
    margin-bottom: 10px;
    /* Space below the title */
    font-size: 1.5em;
    /* Title size */
    color: #333;
    /* Title color */
}

.landingpage .card_info .row p {
    font-size: 1em;
    /* Content size */
    color: #555;
    /* Content color */
}

.landingpage .partners {
    display: flex;
    justify-content: space-around;
}

.landingpage .partners img {
    width: 15%;
}

.landingpage .panters_body button {
    background-color: #f6f7fb;
}

.landingpage .ipos {
    display: flex;

}

.landingpage .ipos p {
    font-size: 25px;
}

.landingpage .country_item {
    background-color: #1ea698;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    /* Smooth transitions */
}

.landingpage .country_item:hover {
    transform: scale(1.05);
    /* Slightly enlarge the item */
    background-color: #17a689;
    /* Change background color on hover */
}

.landingpage .country_item h3 {
    display: flex;
    /* Use flexbox on country_item */
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
    margin: 10px;
    /* Add spacing between items */
    text-align: center;
    color: #f0ebeb;
    /* Center text within each item */
    /* Add any additional styles here */
}

.landingpage .country_item img {
    width: 100%;
}

.order_1 {
    z-index: 1;

}

.content-body button {
    background-color: #17a689;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
}

.landingpage .bottom_button button {
    background-color: #17a689;
    color: white;
    border: none;
    padding: 10px;
}


@media only screen and (max-width: 600px) {
    .landingpage .content .content-body .h-1 {
        font-size: 27px;
        color: #ff7f00;
    }

    .landingpage .content {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 367px;
    }

    .landingpage .content .content-body .h-2 {
        color: black;
        font-weight: 900;
        font-family: Jost;
        font-size: 46px;
    }

    .landingpage .order_1 {
        order: 1;
    }

    .body h3 {
        margin-top: 10px;
        font-size: 25px !important;
        color: #4B144B;
    }

    .landingpage .cardpoint {
        width: 90%;
        margin: 5%;
        position: relative;
        background-color: white;
        border-radius: 10px;
        height: 380px;
    }

    .landingpage .funstore {
        margin: 10px 3%;
        text-align: center;
        border: 1px solid black;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 7px 11px 12px #0000005c;
    }



    .landingpage .if_uni {
        background-color: #f6f7fb87;
        /* margin-top: 0px; */
        /* position: absolute; */
        z-index: 17;
        margin-top: 0px;
        margin-left: -14px;
        /* display: none; */
    }

    .landingpage .top-img img {
        
        height: 200px !important;
        width: 100%;
        border-radius: 0px;
        margin-bottom: 10px;
        box-shadow: 13px 3px 12px #afa8a8;
        margin-left: 0px;
        margin-top: -30px;
    }
}